import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';

const debt = [];

export default class Customers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      records: this.props.records,
      paidAmount: 0,
      cateringAmount: 0
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.setState({debt: this._getDebt()})
  }

  _editPath(id) {
    return this.props.edit_path.replace('_id_', id)
  }

  _destroyPath(id) {
    return this.props.destroy_path.replace('_id_', id)
  }

  _renderTitle() {
    return <h2 className="clearfix">
            {this.props.site_name}
      <div className="pull-right">
        <a href="?all=1" className="btn btn-warning btn-sm">pokaż wszystkich</a>
        &nbsp;
        <a href="/admin/customers/" className="btn btn-success btn-sm">tylko aktywnych</a>
        &nbsp;
        <a href={this.props.new_path} className="btn btn-info btn-sm">Dodaj nowy rekord</a>
            </div>
        </h2>
  }

  _removeRow(event) {
    event.preventDefault();
    let id = event.target.dataset.id;
    let id_key = event.target.dataset.id_key;
    if (confirm('Are you sure?')) {
      axios.delete(this._destroyPath(event.target.dataset.id), {
        headers: {'X-CSRF-Token': this.props.csrf_token},
      })
          .then((response) => {
            this._removeRecord(id_key);
          })
          .catch((error) => {
            console.log(error);
          });
    } else {
      console.log('uff');
    }
  }

  _removeRecord(index) {
    let records = this.state.records;
    records.splice(index, 1);
    this.setState({records});
  }

  _renderTable() {
    return <table className="table table-bordered table-hover">
            <thead>
            {this._renderIdentifiers()}
            </thead>
          {this._renderData()}
        </table>
  }

  _renderIdentifiers() {
    let colNames = [];
    _.mapValues(this.props.identifiers, (identifier) => colNames.push(<th key={identifier}>{identifier}</th>));
    return <tr>
      <td>LP</td>
      {colNames}
      <th>Action</th></tr>

  }

  _renderData() {
    if (!this.state.records) {
      console.log('ERR: records are empty!');
      return null;
    }

    let records = [];
    _.mapValues(this.state.records, (record, index) => {
      records.push(this._renderRow(record, index));
    });

    return <tbody>{records}</tbody>;
  }

  _renderRow(record, index) {
    // I assume, that record always has id property
    let colValues = [];
    let balance = 0;

    _.mapKeys(this.props.identifiers, (value, identifier) => {
      let key = record.id + '.' + identifier;
      let fieldValue = record[identifier];
      console.log({identifier}, {value});
      switch(identifier) {
        case 'balance':
          fieldValue = this._balance(record);
          balance = fieldValue;
          this._addDebt(balance);
          break;
        case 'created_at':
          console.log({record});
          fieldValue = record.created_at.split('T')[1];
          break;
      }
      colValues.push(<td key={key}>{fieldValue}</td>)
    });

    return (
        <tr key={index} className={balance >= 0 ? 'success' : 'danger'}>
          <td>{index*1+1}.</td>
            {colValues}
          <td>
            <a href={"/admin/customers/" + record.id + "/payments"} className="btn btn-xs btn-warning">payments</a>
            &nbsp;&nbsp;
            <a href={this._editPath(record.id)} className="btn btn-success btn-xs">edit</a>
            &nbsp;&nbsp;
            <a href="#" onClick={(e) => this._removeRow(e)} data-id_key={index} data-id={record.id}
                className="btn btn-danger btn-xs">delete</a>
            </td>
        </tr>
    )
  }

  _balance(record) {
    return record.paid_amount - record.catering_amount
  }

  _addDebt(balance) {
    if (balance < 0) {
      debt.push(balance);
    }
    return true;
  }
  _renderBalance() {

    if (this.props.show_money != '1') {
      return null;
    }
    let balance = this._globalBalance();

    return (<h2>
        <div className="row">
          <div className="col-sm-3 text-success">Wpływy: {balance.paidAmount}</div>
          <div className="col-sm-3 text-danger">Długi: -{balance.cateringAmount}</div>
          <div className="col-sm-3 text-danger">Bilans: {balance.paidAmount - balance.cateringAmount}</div>
          <div className="col-sm-3 text-danger">Bilans II: {this.state.debt}</div>
        </div>
        </h2>)
  }
  _globalBalance() {
    let paidAmount = 0;
    let cateringAmount = 0;

    _.mapValues(this.state.records, (record, index) => {
      paidAmount += record.paid_amount*1;
      cateringAmount += record.catering_amount*1;
    });

    return {paidAmount, cateringAmount};
  }

  _getDebt() {
    return _.sum(debt);
  }

  render() {
    return <div>
      {this._renderBalance()}
      {this._renderTitle()}
      {this._renderTable()}
    </div>
  }
}
