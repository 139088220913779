import React from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import _ from 'lodash';
import axios from 'axios';

const SortableItem = SortableElement(({customer, index, deliverFoodFn}) => {
  return (<li className='road-trip'>
    <div className="basic-info">
      <SortableHnadle/>
      <strong>{customer.full_name}</strong> ({_cateringsQuantity(customer)})
      <br/>
      {customer.address}, {customer.city}
    </div>
  </li>)
});

const SortableList = SortableContainer(({items, deliverFoodFn}) => {
  return (
    <ul className="road-trip">
      {items.map((customer, index) => (
        <SortableItem key={`item-${index}`} index={index} customer={customer} deliverFoodFn={deliverFoodFn}/>
      ))}
    </ul>
  );
});

const SortableHnadle = SortableHandle(() => {
  return <span className='sortable-hnadle btn btn-danger'><i className="fa fa-arrows-alt-v fa-2x"/></span>
});


function _cateringsQuantity(customer) {
  let qty = 0;
  return qty + customer.quantity_1000 * 1 + customer.quantity_1200 * 1 + customer.quantity_1500 * 1 + customer.quantity_1750 * 1
    + customer.quantity_2000 * 1 + customer.quantity_2500 * 1 + customer.quantity_3000 * 1
}

export default class RoadPlanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this._handleCustomers(),
      sort: 'asc'
    };
  }

  _handleCustomers() {
    let sorted_customers = [];
    _.mapValues(this.props.records, (customer) => {
      sorted_customers.push(customer)
    });
    return sorted_customers;
  }

  onSortEnd({oldIndex, newIndex}) {
    const newItems = arrayMoveImmutable(this.state.items, oldIndex, newIndex);
    this.setState({
      items: newItems
    });
    console.log(this.state.items, newItems, oldIndex, newIndex);
    axios.put(this.props.customers_rank_url, {
      customers: JSON.stringify(this.state.items)
    }, {
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json'
      }
    })
  };

  _deliverFood(e, customer, index) {
    e.preventDefault();
    let customers = this.state.items;
    customers.splice(index, 1);
    const amount = prompt('Podaj kwotę, jaką zapłacił klient (tylko liczba: 100):', 0);
    if (amount != null) {
      this.setState({
        items: customers
      });
      this._handleRequest(customer, Number(amount))
    }
  }

  _handleRequest(customer, amount) {
    axios.post(this.props.delivered_path, {
      amount: amount,
      quantity: _cateringsQuantity(customer),
      customer_id: customer.id
    }, {
      headers: {'X-CSRF-Token': this.props.csrf_token},
    })
      .then((response) => {
        // this.setState({foods: response.data})
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        // this.setState({loading: false})
      });
    // this.props.removeCustomer(this.props.customer.id);
  }

  render() {
    return <div>
      <br/>
      <a href={`/road_trip/${this.props.route}`} className="btn btn-warning">Powrót do trasy</a>
      <br/>
      <SortableList items={this.state.items} lockAxis='y' useDragHandle={true} helperClass='road-trip'
                    onSortEnd={this.onSortEnd.bind(this)} deliverFoodFn={this._deliverFood.bind(this)}/>
    </div>
  }
}
