import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import Autocomplete from 'react-autocomplete';

export default class CalculatorRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: '', item: null, foods: [], quantity: 0, kcal: 0};
  }

  componentDidMount() {
    console.log('componentDidMount');
  }


  _getFood(name) {
    console.log(name);
    this.setState({ value: name, loading: true });
    if (name.length % 3 == 0) {
      axios.post('/admin/food/', {
        query: name
      }, {
        headers: {'X-CSRF-Token': this.props.csrf_token},
      })
          .then((response) => {
            console.log(name);
            console.log(response);
            this.setState({ foods: response.data, loading: false })
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false })
          });
    }
  }

  _handleQuantityChange(value) {
    this._calculateKcalFromQuantities(value);
    this._handleTotalQuantities(value);
    this.setState({quantity: value});
  }

  _handleKcalChange(value) {
    this._calculateQuantitiesFromKcal(value);
    this.setState({kcal: value});
    this._handleTotalKcals(value);
  }

  _calculateQuantitiesFromKcal(value) {
    if (this.state.item.kcal > 0) {
      let quantity = (value / this.state.item.kcal) * this.state.item.base_quantity;
      this.setState({quantity});
      this._handleTotalQuantities(quantity);
    }
  }

  _calculateKcalFromQuantities(value) {
    if (this.state.item.base_quantity > 0) {
      let kcal = (value / this.state.item.base_quantity) * this.state.item.kcal;
      this.setState({kcal});
      this._handleTotalKcals(kcal);
    }
  }

  _handleTotalQuantities(value) {
    this.props.totalQuantities(this.props.row_id, value);
  }

  _handleTotalKcals(value) {
    this.props.totalKcals(this.props.row_id, value);
  }

  render() {
    return <tr id="clone">
      <td>
      <Autocomplete
          inputProps={{className: 'form-control autocomplete'}}
          getItemValue={(item) => item.name}
          items={this.state.foods}
          renderItem={(item, isHighlighted) =>
              <div style={{background: isHighlighted ? 'lightgray' : 'white'}}>{item.name}</div>
          }
          value={this.state.value}
          onSelect={(value, item) => {
            // set the menu to only the selected item
            this.setState({value, foods: [item], item, kcal: item.kcal, quantity: item.base_quantity });
            {/*console.log(item);*/}
            this._handleTotalKcals(item.kcal);
            this._handleTotalQuantities(item.base_quantity);
            // or you could reset it to a default list again
            // this.setState({ unitedStates: getStates() })
          }}
          onChange={(event, value) => {
            this._getFood(value);
          }}
      />
        </td>
      <td> <input type="number" value={this.state.quantity} onChange={(e) => this._handleQuantityChange(e.target.value)} /></td>
      <td> <input type="number" value={this.state.kcal} onChange={(e) => this._handleKcalChange(e.target.value)} /></td>
    </tr>
  }
}