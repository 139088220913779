import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import DeliveryCustomer from "./DeliveryCustomer";


const debt = [];

export default class Deliveries extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      records: this.props.customers,
      filter: ''
    };
  }

  _handleChange(event) {
    let inputVal = {};
    inputVal[event.target.name] = event.target.value;
    this.setState(inputVal);

  }

  _renderTitle() {
    return <h2>{this.props.site_name}</h2>
  }

  _renderTable() {

    return <table className="table table-bordered table-hover">
            <thead>
            <tr>
              <th className="text-left">
                <div className="col-xs-3">Imię i nazwisko</div>
                <div className="col-xs-3">ilość zestawów</div>
                <div className="col-xs-3">wpłacona kwota</div>
              </th>
            </tr>
            <tr>
              <td>
                <input type="text" name="filter" placeholder="wpisz nazwisko" value={this.state.filter}
                    className="form-control" onClick={(e) => e.target.select()}
                    onChange={(e) => this._handleChange(e)}/>
              </td>
          </tr>
        </thead>
          {this._renderData()}
        </table>
  }

  removeCustomer(id) {
    let customers = this.state.records;
    customers = _.remove(customers, (customer) => ( customer.id != id ));
    this.setState( {records: customers} )
  }

  _renderData() {
    if (!this.state.records) {
      console.log('Check your records, because their are empty!');
      return null;
    }

    let current_records = this.state.records;
    if (this.state.filter != '') {
      current_records = _.filter(current_records,
          (record) => { return record.full_name.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1 })
    }
    let records = [];
    _.mapValues(current_records, (record, index) => {
      records.push(this._renderRow(record));
    });

    return <tbody>{records}</tbody>;
  }

  _renderRow(record) {
    return <DeliveryCustomer
        key={record.id} customer={record}
        create_path={this.props.create_path}
        csrf_token={this.props.csrf_token}
        removeCustomer={this.removeCustomer.bind(this)} />
  }

  render() {
    return <div>
      {this._renderTitle()}
      {this._renderTable()}
    </div>
  }
}
