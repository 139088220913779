import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class Meal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }


  _renderTitle() {
    return <h2 className="clearfix">{this.props.site_name}</h2>
  }

  _totalQuantities(index, value) {
    this.state.total_quantities[index] = parseFloat(value);
    this.setState({total_quantities: this.state.total_quantities})
  }

  _totalKcals(index, value) {
    this.state.total_kcals[index] = parseFloat(value);
    this.setState({total_kcals: this.state.total_kcals})
  }

  _renderCalculateRow() {

    let rows = [];
    for(let i = 0; i < 50; i++) {
      rows.push(<CalculatorRow key={i} row_id={i} csrf_token={this.props.csrf_token} totalQuantities={this._totalQuantities.bind(this)} totalKcals={this._totalKcals.bind(this)} />)
    }

    return rows;
  }

  render() {
    return <div>
            {this._renderTitle()}
      <table className="table table-bordered table-hover">
        <tbody>
        <tr>
          <th>Składnik</th>
          <th>Ilość gram</th>
          <th>Ilość kalorii</th>
        </tr>
        {this._renderCalculateRow()}
        <tr>
          <td>&nbsp;</td>
          <td><strong>{_.sum(this.state.total_quantities)}</strong></td>
          <td><strong>{_.sum(this.state.total_kcals)}</strong></td>
        </tr>
        </tbody>
      </table>
        </div>
  }
}