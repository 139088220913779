import React from 'react';
import _ from 'lodash';
import axios from 'axios';

const debt = [];

export default class DeliveryCustomer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      quantity: this._setCateringQuantity(),
    };
    // console.log(this.state);
  }


  _handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  componentDidMount() {
    console.log('componentDidMount');
  }

  _saveAmount(e) {
    e.preventDefault();
    // console.log(this.props.customer);
    // console.log(this.state.amount);

    // this.setState({value: name, loading: true});
    // console.log(this.state);
    axios.post(this.props.create_path, {
      amount: this.state.amount,
      quantity: this.state.quantity,
      customer_id: this.props.customer.id
    }, {
      headers: {'X-CSRF-Token': this.props.csrf_token},
    })
        .then((response) => {
          // this.setState({foods: response.data})
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          // this.setState({loading: false})
        });
    this.props.removeCustomer(this.props.customer.id);
    this.setState({amount: 0})
  }

  _balance(record) {
    let balance = record.paid_amount - record.catering_amount;
    if (balance < 100 && balance > -100) {
      return <span>({balance})</span>;
    }
    return null;
  }

  _setCateringQuantity() {
    return 1;
    // return (parseInt(this.props.customer.quantity_1000) + parseInt(this.props.customer.quantity_1500) + parseInt(this.props.customer.quantity_1750) + parseInt(this.props.customer.quantity_2000) + parseInt(this.props.customer.quantity_3000))
  }

  _renderCalories() {
    let calories = [];

    let kcals = [1000, 1500, 1750, 2000, 3000];

    _.forEach(kcals, (kcal) => {
      if (this.props.customer["quantity_" + kcal] > 0) {
        calories.push(parseInt(this.props.customer["quantity_" + kcal]) + ' x ' + kcal)
      }
    })

    return <span>{calories.join(', ')}</span>
  }

  render() {
    return <tr>
      <td>
        <form className="form-inline">
          <div className="col-xs-3">
            <strong className="customer">
              {this.props.customer.full_name} - {this.props.customer.area} zł  {this._balance(this.props.customer)}
              <br/>
              {this._renderCalories()}
            </strong>
            </div>
          <div className="col-xs-3">
                <input type="number" min="0" name="quantity" value={this.state.quantity}
                    className="form-control" onClick={(e) => e.target.select()}
                    onChange={(e) => this._handleChange(e)}/>
          </div>
          <div className="col-xs-3">
                <input type="number" name="amount" value={this.state.amount} className="form-control"
                    onClick={(e) => e.target.select()} onChange={(e) => this._handleChange(e)}/>
          </div>
          <div className="col-xs-2">
                <button type="submit" onClick={(e) => this._saveAmount(e)}
                    className="btn btn-sm btn-warning">zapisz</button>
          </div>
            </form>
          </td>
        </tr>
  }
}
