import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import Ingredient from './Ingredient';

export default class Ingredients extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saved_ingredients: false,
      quantities: [{
        product_id: null,
        base: {qty: 0, kcal: 0},
        1500: {qty: 0, kcal: 0},
        1750: {qty: 0, kcal: 0},
        2000: {qty: 0, kcal: 0},
        3000: {qty: 0, kcal: 0},
      }],
      meal_id: this.props.meal.id
    };
  }

  _editPath(id) {
    return this.props.edit_path.replace('_id_', id)
  }

  _destroyPath(id) {
    return this.props.edit_path.replace('_id_', id)
  }

  _renderTitle() {
    return <h2>{this.props.site_name}</h2>
  }

  _removeRow(event) {
    event.preventDefault();
    let id = event.target.dataset.id;
    let id_key = event.target.dataset.id_key;
    if (confirm('are you sure?')) {
      axios.delete(this._destroyPath(event.target.dataset.id), {
        headers: {'X-CSRF-Token': this.props.csrf_token},
      })
        .then((response) => {
          this._removeRecord(id_key);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('uff');
    }
  }

  _removeRecord(index) {
    let records = this.state.records;
    records.splice(index, 1);
    this.setState({records});
  }

  _roundNum(number) {
    return parseFloat(number).toFixed(2)
  }

  _totalKcals(product, quantity, kcal, qty_type, index) {
    if (!this.state.quantities[index]) {
      this.state.quantities[index] = {
        product_id: null,
        base: {qty: 0, kcal: 0},
        1500: {qty: 0, kcal: 0},
        1750: {qty: 0, kcal: 0},
        2000: {qty: 0, kcal: 0},
        3000: {qty: 0, kcal: 0},
      }
    }
    this.state.quantities[index]['product_id'] = product.id;
    this.state.quantities[index][qty_type] = {qty: this._roundNum(quantity), kcal: this._roundNum(kcal)};
    this.setState({quantities: this.state.quantities});
    // console.log(this.state.quantities);
  }

  _handleSubmit(event) {
    event.preventDefault();

    axios.post(this.props.new_path, {
      ingredients: JSON.stringify(this.state)
    }, {
      headers: {'X-CSRF-Token': this.props.csrf_token},
    })
      .then((response) => {
        // this.setState({saved_ingredients: true})

        this.setState({
          saved_ingredients: true
        }, () => {
          window.setTimeout(() => this.setState({saved_ingredients: false}), 2000)
        });

      })
      .catch((error) => {
        console.log(error);
      });
    console.log('sent');

  }

  _renderIngredient() {
    let rows = [];

    for (let i = 0; i < 20; i++) {
      rows.push(<Ingredient key={i} row_id={i} csrf_token={this.props.csrf_token} meal_id={this.props.meal.id}
                            totalKcals={this._totalKcals.bind(this)} ingredient={this.props.ingredients[i]}/>)
    }
    return rows;
  }

  render() {
    return <div>
      {this._renderTitle()}
      <form onSubmit={(e) => this._handleSubmit(e)}>
        <div className="text-center">
          <input type="submit" value="Zapisz składniki" className="btn btn-warning" />
          {this.state.saved_ingredients ? ' ...zapisałem' : ''}
        </div>
        <br/>
        <table className="table table-bordered table-hover kcals">
          <tbody>
          <tr>
            <th>Składnik</th>
            <th>2000 <em>({this._roundNum(_.sumBy(this.state.quantities, (v) => v[2000].kcal * 1))})</em></th>
            <th>1500 <em>({this._roundNum(_.sumBy(this.state.quantities, (v) => v[1500].kcal * 1))})</em></th>
            <th>1750 <em>({this._roundNum(_.sumBy(this.state.quantities, (v) => v[1750].kcal * 1))})</em></th>
            <th>3000 <em>({this._roundNum(_.sumBy(this.state.quantities, (v) => v[3000].kcal * 1))})</em></th>
            <th>Ilość <em>({this._roundNum(_.sumBy(this.state.quantities, (v) => v.base.kcal * 1))})</em></th>
          </tr>
          {this._renderIngredient()}
          </tbody>
        </table>

      </form>
    </div>
  }
}
