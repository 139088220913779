export const summarizeCheckboxes = (baseClassName, summaryElem) => {
  const all = `${baseClassName}__all`;
  const items = `${baseClassName}__item`;

  const summarizeValues = () => {
    const checkedItems = $(`${items}:checked`);
    if (checkedItems.length === 0) {
      return printSummary(0);
    }
    let currVal = 0;
    checkedItems.each((index, item) => {
      currVal += +item.value;
    })
    printSummary(currVal);

  }

  const printSummary = (value) => {
    $(summaryElem).text(value.toFixed(2));
  }

  const controlCurrentState = () => {
    if ($(items).length === $(`${items}:checked`).length) {
      $(all).prop('checked', true)
    } else {
      $(all).prop('checked', false)
    }
  }

  const updateRecords = (id, attributes) => {
    $.ajax({
      url: `/admin/invoices/${id}`,
      type: 'PUT',
      data: { invoice: attributes }
    });
  }

  $(all).on('click', function () {
    $(items).prop('checked', $(this).prop('checked'))
    summarizeValues()
  });

  $(items).on('click', function () {
    summarizeValues()
    controlCurrentState()

    if (+$(this).data('id') > 0) {
      updateRecords($(this).data('id'), {is_paid: $(this).prop('checked')})
    }
  });

  controlCurrentState();
  summarizeValues();
}

export default summarizeCheckboxes;
