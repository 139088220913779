import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import Autocomplete from 'react-autocomplete';

export default class Ingredient extends React.Component {
  constructor(props) {
    super(props);

    let ingredients = {};
    let default_state = {};

    if (this.props.ingredient) {
      ingredients = {
        qty_type: 'gr',
        product_id: this.props.ingredient.product.id,
        quantity: this.props.ingredient.quantity,
        qty_1500: this.props.ingredient.qty_1500,
        qty_1750: this.props.ingredient.qty_1750,
        qty_2000: this.props.ingredient.qty_2000,
        qty_1500_bckp: this.props.ingredient.qty_1500_bckp,
        qty_1750_bckp: this.props.ingredient.qty_1750_bckp,
        qty_2000_bckp: this.props.ingredient.qty_2000_bckp,
        qty_3000: this.props.ingredient.qty_3000,
      };

      default_state = {
        value: this.props.ingredient.product.name + ' (' + this.props.ingredient.product.kcal + ')',
        product: this.props.ingredient.product, foods: [], ingredients: ingredients,
        kcal: ((this.props.ingredient.quantity / this.props.ingredient.product.base_quantity) * this.props.ingredient.product.kcal).toFixed(2),
        kcal_1500: ((this.props.ingredient.qty_1500 / this.props.ingredient.product.base_quantity) * this.props.ingredient.product.kcal).toFixed(2),
        kcal_1750: ((this.props.ingredient.qty_1750 / this.props.ingredient.product.base_quantity) * this.props.ingredient.product.kcal).toFixed(2),
        kcal_2000: ((this.props.ingredient.qty_2000 / this.props.ingredient.product.base_quantity) * this.props.ingredient.product.kcal).toFixed(2),
        kcal_3000: ((this.props.ingredient.qty_3000 / this.props.ingredient.product.base_quantity) * this.props.ingredient.product.kcal).toFixed(2),
      };
    } else {
      ingredients = {
        qty_type: 'gr',
        product_id: 0,
        quantity: 0,
        qty_1500: 0, qty_1750: 0, qty_2000: 0, qty_3000: 0,
      };

      default_state = {
        value: '', product: null, foods: [], ingredients: ingredients,
        kcal: 0, kcal_1500: 0, kcal_1750: 0, kcal_2000: 0, kcal_3000: 0
      };
    }
    this.state = default_state
  }

  componentDidMount() {
    if (this.state.product) {
      this._handleTotalKcals(this.props.ingredient.quantity, this.state.kcal, 'base', this.props.ingredient.product);
      this._handleTotalKcals(this.props.ingredient.qty_1500, this.state.kcal_1500, 1500, this.props.ingredient.product);
      this._handleTotalKcals(this.props.ingredient.qty_1750, this.state.kcal_1750, 1750, this.props.ingredient.product);
      this._handleTotalKcals(this.props.ingredient.qty_2000, this.state.kcal_2000, 2000, this.props.ingredient.product);
      this._handleTotalKcals(this.props.ingredient.qty_3000, this.state.kcal_3000, 3000, this.props.ingredient.product);
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log('will receive ...');
  //   console.log(nextProps);
  // }

  _getProduct(name) {
    this.setState({value: name, loading: true});

    if (name.length >= 1) {
      axios.post('/admin/food/', {
        query: name
      }, {
        headers: {'X-CSRF-Token': this.props.csrf_token},
      })
          .then((response) => {
            console.log(response.data);
            this.setState({foods: response.data, loading: false})
          })
          .catch((error) => {
            console.log(error);
            this.setState({loading: false})
          });
    } else {
      this.setState({value: name, loading: false});
    }
  }

  _handleChange(event, calculate_kcals = false) {
    this.state.ingredients[event.target.name] = event.target.value;
    this.setState({ingredients: this.state.ingredients});
    if (calculate_kcals) {
      this._calculateKcalFromQuantities(event)
    }
  }

  _copyMeasuresIfNeeded(event) {

    let calculated_calories = {};

    _.forEach({1500: 'qty_1500', 1750: 'qty_1750', 3000: 'qty_3000'}, (v, k) => {
      if (this.state.ingredients[v] == 0) {
        this.state.ingredients[v] = event.target.value;
        calculated_calories[v] = this._calculateKcals(event.target.value, k);
        this.setState({ingredients: this.state.ingredients, calculated_calories});
      }
    });
  }

  _calculateKcalFromQuantities(event) {
    if (this.state.product) {
      let calculated_calories = {};
      let quantity = event.target.value;
      switch (event.target.name) {
        case 'quantity':
          calculated_calories = {kcal: this._calculateKcals(quantity)};
          break;
        case 'qty_1500':
          calculated_calories = {kcal_1500: this._calculateKcals(quantity, 1500)};
          break;
        case 'qty_1750':
          calculated_calories = {kcal_1750: this._calculateKcals(quantity, 1750)};
          break;
        case 'qty_2000':
          calculated_calories = {kcal_2000: this._calculateKcals(quantity, 2000)};
          break;
        case 'qty_3000':
          calculated_calories = {kcal_3000: this._calculateKcals(quantity, 3000)};
          break;
        default:
      }
      this.setState(calculated_calories);
    }
  }

  _calculateKcals(quantity, total_type = 'base') {

    if (this.state.product) {
      let kcal = ((quantity / this.state.product.base_quantity) * this.state.product.kcal).toFixed(2);
      this._handleTotalKcals(quantity, kcal, total_type);
      return kcal;
    }
  }

  _handleTotalKcals(quantity, kcal, total_type, product = null) {
    if (!product) {
      product = this.state.product;
    }
    this.props.totalKcals(product, quantity, kcal, total_type, this.props.row_id);
  }


  _select(event) {
    event.target.select()
  }

  render() {
    console.log('-------',this.ingredient);
    return <tr id="clone">
      <td>
        {this.ingredient}
        <Autocomplete
            inputProps={{className: 'form-control autocomplete', tabIndex: this.props.row_id+1}}
            getItemValue={(product) => product.name}
            items={this.state.foods}
            renderItem={(product, isHighlighted) =>
                <div style={{background: isHighlighted ? 'lightgray' : 'white'}}>{product.name}</div>
            }
            value={this.state.value}
            menuStyle={{
              borderRadius: '3px',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
              background: 'rgba(255, 255, 255, 0.9)',
              padding: '2px 0 2px 10px',
              fontSize: '90%',
              position: 'fixed',
              overflow: 'auto',
              maxHeight: '50%',
            }}
            onSelect={(value, product) => {
              // set the menu to only the selected item
              this.state.ingredients['product_id'] = product.id;
              this.state.ingredients['quantity'] = product.base_quantity;
              this.state.ingredients['kcal'] = product.kcal;
              this.setState({
                value,
                foods: [product],
                product,
                ingredients: this.state.ingredients,
                kcal: product.kcal
              });
              this._handleTotalKcals(product.base_quantity, product.kcal, 'base', product);
              // or you could reset it to a // this.setState({ unitedStates: getStates() })
            }}
            onChange={(event, value) => {
              this._getProduct(value);
            }}
        />
        <input type="hidden" name="product_id" value={this.state.ingredients.product_id}/>
        <input type="hidden" name="meal_id" value={this.props.meal_id}/>
        <input type="hidden" name="qty_type" value="gr"/>
        </td>
      <td className="text-center">
        <input name="qty_2000" className="text-center form-control" type="number"
            value={this.state.ingredients.qty_2000} onClick={(e) => this._select(e)}
            onChange={(e) => this._handleChange(e, true)} onBlur={(e) => this._copyMeasuresIfNeeded(e)} tabIndex={this.props.row_id+21}/>
        <span>{this.state.kcal_2000} | {this.state.ingredients.qty_2000_bckp}</span>
      </td>
      <td className="text-center">
        <input name="qty_1500" className="text-center form-control" type="number"
            value={this.state.ingredients.qty_1500} onClick={(e) => this._select(e)}
            onChange={(e) => this._handleChange(e, true)}/>
        <span>{this.state.kcal_1500} | {this.state.ingredients.qty_2000_bckp}</span>
      </td>
      <td className="text-center">
        <input name="qty_1750" className="text-center form-control" type="number"
            value={this.state.ingredients.qty_1750} onClick={(e) => this._select(e)}
            onChange={(e) => this._handleChange(e, true)}/>
        <span>{this.state.kcal_1750}</span>
      </td>
      <td className="text-center">
        <input name="qty_3000" className="text-center form-control" type="number"
            data-catering="1"
            value={this.state.ingredients.qty_3000} onClick={(e) => this._select(e)}
            onChange={(e) => this._handleChange(e, true)}/>
        <span>{this.state.kcal_3000}</span>
      </td>
      <td className="text-center">
        <input name="quantity" className="text-center form-control" type="number"
            value={this.state.ingredients.quantity} onClick={(e) => this._select(e)}
            onChange={(e) => this._handleChange(e, true)}/>
        <span>{this.state.kcal}</span>
      </td>
    </tr>
  }
}
