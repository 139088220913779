import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';

export default class Caterings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {records: this.props.records};
  }

  componentDidMount() {
    console.log('componentDidMount');
  }

  _editPath(id) {
    return this.props.edit_path.replace('_id_', id)
  }

  _destroyPath(id) {
    return this.props.destroy_path.replace('_id_', id)
  }

  _renderTitle() {
    return <h2 className="clearfix">
            {this.props.site_name}
      <div className="pull-right"><a href={this.props.new_path} className="btn btn-info btn-sm">Nowy Catering</a>
            </div>
        </h2>
  }

  _removeRow(event) {
    event.preventDefault();
    let id = event.target.dataset.id;
    let id_key = event.target.dataset.id_key;
    if (confirm('are you sure?')) {
      axios.delete(this._destroyPath(event.target.dataset.id), {
        headers: {'X-CSRF-Token': this.props.csrf_token},
      })
          .then((response) => {
            this._removeRecord(id_key);
          })
          .catch((error) => {
            console.log(error);
          });
    } else {
      console.log('uff');
    }
  }

  _removeRecord(index) {
    let records = this.state.records;
    records.splice(index, 1);
    this.setState({records});
  }

  _renderTable() {
    return <table className="table table-bordered table-hover">
            <thead>
            {this._renderIdentifiers()}
            </thead>
      {this._renderData()}
        </table>
  }

  _renderIdentifiers() {
    let colNames = [];
    _.mapValues(this.props.identifiers, (identifier) => colNames.push(<th key={identifier}>{identifier}</th>));
    return <tr>{colNames}
      <th>Action</th></tr>

  }

  _renderData() {
    if (!this.state.records) {
      return null;
    }
    let records = [];
    _.mapValues(this.state.records, (record, index) => {
      if (record.is_active) {
        records.push(this._renderRow(record, index))
      }
    });

    return <tbody>{records}</tbody>;
  }

  _renderRow(record, index) {
    // I assume, that record always has id property
    let colValues = [];
    _.mapKeys(this.props.identifiers, (value, identifier) => {
      let key = record.id + '.' + identifier;
      colValues.push(<td key={key}>{record[identifier]}</td>)
    });

    return (
        <tr key={index}>
            {colValues}
          <td>
                <a href={this._editPath(record.id)}
                    className="btn btn-success btn-xs">edit</a>
            &nbsp;&nbsp;
            <a href="#" onClick={(e) => this._removeRow(e)} data-id_key={index} data-id={record.id}
                className="btn btn-danger btn-xs">delete</a>
            </td>
        </tr>
    )
  }

  render() {
    return <div>
            {this._renderTitle()}
            {this._renderTable()}
        </div>
  }
}