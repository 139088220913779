/* eslint no-console:0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import easyAutocomplete from 'easy-autocomplete';
import ReactOnRails from 'react-on-rails';
import Caterings from '../components/Caterings';
import Records from '../components/Records';
import Customers from '../components/Customers';
import CalculatorRow from '../components/CalculatorRow';
import Calculator from '../components/Calculator';
import Meal from '../components/Meal';
import Ingredients from '../components/Ingredients';
import Ingredient from '../components/Ingredient';
import Deliveries from '../components/Deliveries';
import DeliveryCustomer from '../components/DeliveryCustomer';
import RoadTrip from '../components/RoadTrip';
import RoadPlanner from '../components/RoadPlanner';
import summarizeCheckboxes from '../src/summarize_checkboxes';
// import Worksheet from '../components/Worksheet';
// import '../jquery.checkall';

import Chart from 'chart.js/auto';
global.Chart = Chart;
window.easyAutocomplete = easyAutocomplete;
window.summarizeCheckboxes = summarizeCheckboxes;
console.log('Hello World from Webpacker 123');
ReactOnRails.register({ Caterings, Records, Customers, CalculatorRow, Calculator, Meal, Ingredients, Ingredient, Deliveries, DeliveryCustomer, RoadTrip, RoadPlanner });
